import React from 'react';

const projects = [
  {
    name: "Banking Software",
    description: "A secure and scalable banking solution with features for online transactions, customer management, and reports.",
    technologies: "React, Flask ,Firebase",
    repo: "https://github.com/your-repo",
    demo: "https://fincruise.pankajsahu.in",
    img:'https://www.idfcfirstbank.com/content/dam/idfcfirstbank/images/blog/mobile-banking/how-new-age-banking-is-transforming-the-banking-industry-717x404.jpg'
  },
  {
    name: "Doctor Appointment System",
    description: "A web application to schedule doctor appointments, manage patient records, and track medical histories.",
    technologies: "React, Firebase",
    repo: "https://github.com/your-repo",
    demo: "https://your-demo-link.com",
    img:'https://media.istockphoto.com/id/1426832181/photo/cancer-patient-see-doctor-in-a-hospital.jpg?s=612x612&w=0&k=20&c=uV0nXpvP0T_ErBrjzb5V5iFcUrVTUAXcbtcrllTO4Ig='
  },
  {
    name: "Warehousing",
    description: "A warehousing application streamlines inventory management, storage, and distribution processes. It enables real-time tracking of stock levels, automates order fulfillment, and ensures efficient space utilization.",
    technologies: "React,Firebase",
    repo: "https://github.com/your-repo",
    demo: "https://agromaxwarehousing.com",
    img:'https://media.istockphoto.com/id/2169276561/photo/a-closeup-picture-of-a-fresh-tender-tea-leaves-ready-for-harvest-in-a-tea-plantation.webp?a=1&b=1&s=612x612&w=0&k=20&c=xAShYpkWS-34My9VG36nsX6cIBXY3eYobtMafthBatY='
  }
];

const Project = () => {
  return (
         <div className='reveal w100' style={{width:"70%",margin:"auto"}}>
         <h2 style={{color:"#0057A7",textAlign:"center",fontSize:40}}>Latest Projects</h2>
      <div >
        {projects.map((project, index) => (
        <div className="project-list" style={{display:"flex",margin:"auto",alignItems:"center",justifyContent:"center"}}>
           <div className='zoom w100' style={{width:"30%",borderRadius:20,overflow:"hidden"}}>
                <img style={{width:"100%",objectFit:"cover"}} alt='banking' src={project.img}/>
            </div>
          <div className="w100" key={index} style={{padding:20,width:"60%"}}>
            <h3>{project.name}</h3>
            <p>{project.description}</p>
            <p><strong>Technologies Used:</strong> {project.technologies}</p>
            {/* <a href={project.repo} target="_blank" rel="noopener noreferrer">View Code</a> |  */}
            <a href={project.demo} target="_blank" rel="noopener noreferrer">Live Demo</a>
          </div>
          </div>
        ))}
      </div>
      </div>
  );
}

export default Project;
