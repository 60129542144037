import React,{useEffect} from 'react'
import axios from 'axios'

function About() {
const get=async()=>{
  try {
    const res= await axios.get("http://127.0.0.1:5000/tasks")
    console.log(res.data)
  } catch (error) {
    console.log(error)
  }
}
const POST=async()=>{
  try {
    const res= await axios.post("http://127.0.0.1:5000/tasks",{'title': 'New Task'})
    console.log(res.data)
  } catch (error) {
    console.log(error)
  }
}

useEffect(()=>{
  // get()
},[])

  return (
    <div className='reveal' style={{ width:'96%',margin:" 0 2%"}}>
    <div style={{
        padding:"20px",
        display:'flex',flexFlow:"wrap",
        justifyContent:'center',
    }}>
      
      
     <div style={{width:"clamp(400px,48%,50%)",display:"flex",flexDirection:"column",margin:"1%"}}>
      <h2 style={{padding:'5px',margin:"0 10px ",color:"#0057A7"}}> About Me</h2>

      <h3 style={{padding:'5px',margin:"10px",color:"#0057A7"}}>Hi, I'm Pankaj Sahu 👋</h3>
      <p>I'm a passionate React developer dedicated to crafting seamless digital experiences through code. With a keen eye for design and a love for clean, efficient code, I specialize in turning concepts into responsive and user-friendly websites.</p>
      <h3 style={{padding:'5px',margin:"10px",color:"#0057A7"}}>My Approach:</h3>
      <p>I believe that great web development goes beyond lines of code—it's about understanding the end user and creating experiences that resonate with them. My approach is rooted in empathy, placing the user at the center of every design decision to deliver intuitive, user-friendly solutions.</p>
      </div>
      <div style={{width:"clamp(400px,20%,50%)",display:"flex",flexDirection:"column",margin:"1%"}}>
      <h3 style={{padding:'5px',margin:"10px",color:"#0057A7"}}> Skills</h3>
      <div className='skills' style={{display:'flex',flexWrap:"wrap"}}  >
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>HTML</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>CSS</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>JAVASCRIPT</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>REACT JS</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>REACT NATIVE</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>NODE JS</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>FLASK</div>
       <div style={{border:"1px solid",padding:5,margin:10,fontWeight:"bold",borderRadius:5}}>FIREBASE</div>
      </div>
-
      </div>
      
      </div>
    </div>
  )
}

export default About
